import React, { useEffect } from 'react';
import { Link } from 'React/components';
import { TagTitle } from 'React/components';
import IconsMap from './_iconsMap';
import { useObserver } from 'React/custom_hooks';
import './style.scss';

const ModuleLastNews = ({ tagLine, content, moduleCta, modulePath, culture, isLanding }) => {
	

    const accionaDomain = isLanding ? "https://www.acciona.com" : "";

	const newsCol = Array.isArray(content)
    ? content.map((item, idx) => {
        if (item) {
            const refList = Array.isArray(content) ? content.map(() => React.createRef()) : [];

            if (typeof window !== 'undefined') {
                const [observer, setElements, entries] = useObserver({
                    root: null,
                    threshold: 0.25
                });

                const tl = new TimelineMax({ delay: 0, repeat: 0 });

                useEffect(() => {
                    // setting elements to observe
                    const elements = document.querySelectorAll('.module-news .slow--y');
                    if (elements.length > 0) {
                        TweenMax.set(elements, { opacity: 0, y: 90 });
                        setElements(elements);
                    }
                }, [setElements]);

                useEffect(() => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            const lazyItem = entry.target;
                            tl.to(lazyItem, 1, { opacity: 1, y: 0 }, 0.2);
                            observer.unobserve(lazyItem);
                        }
                    });
                }, [entries, observer]);
            }

            const onHover = (event) => {
                const { refId } = event.currentTarget.dataset;
                refList[parseInt(refId)]?.current?.classList.add('is-hovered');
            };

            const notHover = (event) => {
                const { refId } = event.currentTarget.dataset;
                refList[parseInt(refId)]?.current?.classList.remove('is-hovered');
            };

            const maxTextLength = 100;
            const { title, soluciones, tag, date, intro, link, _contentPath } = item || {};

            const introHTMLFree = intro ? intro.replace("</p>", "") : "";

            return (
                <div className="module-news__col slow--y" key={`news-${idx}`} ref={refList[idx]}>
                    {title && (
                        <a href={link ? accionaDomain + link : _contentPath ? accionaDomain + _contentPath : ''} className="link" title={title} target={isLanding ? "_blank" : ""} data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover}>
                            <h2 className="title--s">{title}</h2>
                        </a>
                    )}

                    <div className="tag title--small" key={`tag-${idx}`}>
                        {Array.isArray(soluciones) ? soluciones.slice(0, 2).map((solution, idx) => (
                            <a href={accionaDomain + solution.url} key={`solution-tag-${idx}`} className="link icon--red" title={solution.name} target={isLanding ? "_blank" : ""}>
                                {solution.icon && IconsMap[solution.icon]}
                                {solution && <span>{solution.name}</span>}
                                {idx === 0 && soluciones.length > 1 && <span>,&nbsp;</span>}
                            </a>
                        )) : null}

                        <p className="date">{date}</p>
                    </div>

                    <div>
                        <div className="body--m" dangerouslySetInnerHTML={{ __html: introHTMLFree.length < maxTextLength ? introHTMLFree : `${introHTMLFree.substr(0, maxTextLength)}...` }}></div>
                    </div>
                    {(link || _contentPath) &&
                        <Link type="arrowLong" className="arrow" path={link ? accionaDomain + link : _contentPath ? accionaDomain + _contentPath : ''} target={isLanding ? "_blank" : ""} />
                    }
                </div>
            );
        }
    })
    : null;

    return (
        <section className="module module-news grid">
            {tagLine && (
                <div className="module-news__header slow--y">
                    <TagTitle title={tagLine} />
                    {moduleCta && <Link type="arrow" label={moduleCta ? moduleCta.caption : ''} path={moduleCta ? moduleCta.link : ''} />}
                </div>
            )}

            <div className="grid-container content-wrapper">{newsCol}</div>
            {moduleCta && (
                <div className="cta-footer">
                    <Link type="arrow" label={moduleCta ? moduleCta.caption : ''} path={moduleCta ? moduleCta.link : ''} />
                </div>
            )}
        </section>
    );
};

export default ModuleLastNews;
